$(document).ready(function(){
  $('input[type="tel"]').inputmask('+7 (999) 999-99-99');

  $(document).on('click', '.course-block__btn', function(){
    $('.course-block').toggleClass('course-block--active');
    $(this).toggleClass('course-block__btn--no-animate');
    return false;
  });

  $(document).on('click', '.course-block__close', function(){
    $('.course-block--active').removeClass('course-block--active');
    $('.course-block__btn--no-animate').removeClass('course-block__btn--no-animate');
    return false;
  });

  $('.fancybox').fancybox({
    afterShow: function(instance, current) {
      var btn = $(instance.$trigger);
      var href = btn.attr('href');

      if(href === '#callback-popup') {
        var age = btn.data('age');

        if (age) {
          $(href + ' [name="fields[age]"]').val(age);
        }
      }
    },
    afterClose: function(instance, current) {
      var btn = $(instance.$trigger);
      var href = btn.attr('href');

      if(href === '#callback-popup') {
        $(href + ' [name="fields[age]"]').val('');
      }
    }
  });

  $(document).on('submit', '.form-course', function(){
    var link = $(this).find('[name="course-link"]').val();
    var responseBlock = $(this).find('.form__response');
    var iframeMessage = $('.course-iframe-message');
    var iframeMessageLink = $('.course-iframe-message a');

    responseBlock.text('');

    if(link.indexOf('https://events.webinar.ru/course-info/') === 0){
      $.fancybox.open({
        src: link,
        type: 'iframe',
        opts: {
          afterShow: function(){
            iframeMessage.addClass('course-iframe-message--visibility');
            iframeMessageLink.attr('href', link);
          },
          beforeClose: function(){
            $('.course-iframe-message--visibility').removeClass('course-iframe-message--visibility');
            iframeMessageLink.attr('href', '#');
          }
        }
      });
    } else {
      responseBlock.text('Введен некорректный адрес');
    }

    return false;
  });

  $(document).on('click', '.fancybox-close-btn', function(){
    $.fancybox.close();
    return false;
  });

  $(window).scroll(function(){
    var top = $(this).scrollTop();
    var documentHeight = $(document).outerHeight(true);
    var headerHeight = $('.header').outerHeight();
    var footerHeight = $('.footer').outerHeight();
    var windowHeight = $(window).height();

    if(top > 0){
      $('.header').addClass('header--shadow');
    } else {
      $('.header').removeClass('header--shadow');
    }

    if(top > headerHeight){
      $('.header').addClass('header--fixed');
      $('.mobile-menu').addClass('mobile-menu--fixed');
    } else {
      $('.header').removeClass('header--fixed');
      $('.mobile-menu').removeClass('mobile-menu--fixed');
    }

    if(top > documentHeight - footerHeight - windowHeight){
      $('.fixed-block').addClass('fixed-block--hidden');
      $('#vk_community_messages').addClass('hidden');
      $('.course-btn').addClass('course-btn--hidden');
    } else {
      $('.fixed-block--hidden').removeClass('fixed-block--hidden');
      $('#vk_community_messages.hidden').removeClass('hidden');
      $('.course-btn--hidden').removeClass('course-btn--hidden');
    }
  });

  $(document).on('submit', '.form-ajax', function(){
    var form = $(this);
    var action = form.attr('action');
    var method = form.attr('method');
    var formData = new FormData(form.get(0));
    var parentsPopup = $(this).parents('.popup-block');

    form.find('.form__response').addClass('d-none');

    $.ajax({
      url: (action) ? action : location.href,
      type: (method) ? method : 'post',
      data: formData,
      contentType: false,
      processData: false,
      success: function(data){
        var data = JSON.parse(data);

        if(data.success && parentsPopup.length === 0){
          form.find('.form__response').addClass('form__response--success');
          form.find('.form__response').text(data.text);
        } else if(data.success && parentsPopup.length > 0){
          $.fancybox.close();
          $.fancybox.open({
            src:'#callback-popup-done',
            type:'inline'
          });
        } else {
          form.find('.form__response').text(data.text);
          form.find('.form__response').removeClass('form__response--success');
        }

        if(data.success){
          ym(66626158,'reachGoal','ORDERYES');
        }

        form.find('.form__response').removeClass('d-none');
      }
    });

    return false;
  });

  $(document).on('click', '.menu__link, .mobile-menu__link', function(){
    var id = $(this).attr('href');
    var top = 0;
    var marginTop = 0;
    var windowWidth = $(window).width();
    var headerHeight = $('.header').outerHeight();
    var mobileMenuHeight = $('.mobile-menu').outerHeight();

    if(windowWidth <= 575){
      marginTop = mobileMenuHeight;
    } else {
      marginTop = headerHeight;
    }

    $('.menu').removeClass('menu--active');
    $('.wrapper').removeClass('wrapper--active');
    $('.mobile-menu__wrapper--active').removeClass('mobile-menu__wrapper--active');
    $('.burger--active').toggleClass('burger--active');

    if($(id).length > 0){
      var top =  $(id).offset().top - marginTop - 15;
  		$('html, body').animate({ scrollTop: top }, 500);
    }

    return false;
  });

  $(document).on('click', '.mobile-btn', function(){
    $('.menu').toggleClass('menu--active');
    $('.wrapper').toggleClass('wrapper--active');
    $('.mobile-menu__wrapper').toggleClass('mobile-menu__wrapper--active');
    $(this).toggleClass('burger--active');

    return false;
  });

  $(document).on('click', '.wrapper--active', function(){
    $('.menu').removeClass('menu--active');
    $('.wrapper').removeClass('wrapper--active');
    $('.mobile-menu__wrapper--active').removeClass('mobile-menu__wrapper--active');
    $('.burger--active').toggleClass('burger--active');

    return false;
  });


  //Табы
  let tabNavs = document.querySelectorAll('.tabs__item');
  let tabPanes = document.querySelectorAll('.tabs-content');

  for(let i = 0; i < tabNavs.length; i++) {
    tabNavs[i].addEventListener('click', function (e) {
      let activeTabAttr = e.target.getAttribute('data-tab');
      
      for(let j = 0; j < tabNavs.length; j++ ){
        let contentAttr = tabPanes[j].getAttribute('data-tab-content');
        if(activeTabAttr === contentAttr){
          tabNavs[j].classList.add('tabs__item--active');
          tabPanes[j].classList.add('tabs-content--active');
          // console.log($(tabNavs[j]).parents().hasClass('tabs-content--active'));
          }else{
            tabNavs[j].classList.remove('tabs__item--active');
            tabPanes[j].classList.remove('tabs-content--active');
          }
        }
    });
  }

  $('.filter-btn .filter-burger').on('click', function () {
    $('body').find('.filter-mobile').toggleClass('filter-mobile--active');
  })
  $('.filter-mobile').find('.cross').on('click', function () {
    $('body').find('.filter-mobile').removeClass('filter-mobile--active');
    $('body').find('.filter-burger').removeClass('burger--active');
    $('body').find('.mobile-menu__wrapper').removeClass('mobile-menu__wrapper--active');
  })
});
